import React, { useContext } from "react";
import { Spanlink } from "../../common/Spanlink";
import { GMDContext } from "../../context/GMDContext";
import { currentYear } from "../../../utils/common";

import * as styles from "./Header.module.scss";

const ConditionalWrapper = ({ condition, wrapper, children, className }) =>
  condition ? wrapper(children) : children;

const Header = ({ className, location }) => {
  const { studentYear } = useContext(GMDContext);

  const link = location.pathname.split("/")[1] || "stories";

  // // compare the current year in utils to the studentyear set in context in the student template
  // const link ==== link;
  const linkTo = link === "stories" ? "" : link;
  const linkText = link === "stories" ? "stories" : link;

  return (
    <header className={styles.header} aria-label="Header">
      <nav aria-labelledby="mainmenulabel">
        <h2 id="mainmenulabel" className="visually-hidden">
          Main Menu
        </h2>
        <ul>
          <li className={styles.first}>
            <Spanlink
              to="http://www.gmdlcc.com"
              uppercase
              between
              // hoverStart
            >
              GMD
            </Spanlink>
          </li>
          <li className={styles.last}>
            {/* <ConditionalWrapper
              condition={linkTo !== "stories"}
              wrapper={(children) => (
                <Spanlink
                  to={`/${linkTo}`}
                  uppercase
                  between
                  // hoverEnd
                >
                  {children}
                </Spanlink>
              )}
            >
              {linkText}
            </ConditionalWrapper> */}
            <Spanlink
              to="/"
              uppercase
              between
              // hoverStart
            >
              STORIES
            </Spanlink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export { Header };
